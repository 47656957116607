import React, { Component, Fragment } from 'react'
import { NavLink, withRouter } from 'react-router-dom'
import { injectIntl } from 'react-intl'
import CSSModules from 'react-css-modules'
import { localisedUrl } from 'helpers/locale'
import config from 'helpers/externalConfig'
import { menuItemsData } from './MenuItemsData'
import styles from './Nav.scss'

import AppMenu from './AppMenu'

type NavProps = {
  menu: IUniversalObj[]
  intl: any
}
type SubMenuItem = {
  title: string
  url: string
  info: string
}

// In a global .d.ts file (e.g., global.d.ts)
declare module 'react' {
  interface CSSProperties {
    '--arrow-position'?: string // Add custom CSS property
  }
}
type NavState = {
  activeSubMenu: string | null
  hoveredItemIndex: number | null
  arrowPosition: string // Add this line
  activeMenuIndex: string | null
  isMenuOpen: boolean
}
// @ts-ignore: strictNullChecks
@withRouter
@CSSModules(styles, { allowMultiple: true })
class Nav extends Component<NavProps, NavState> {
  // Class property for refs
  menuItemRefs: Array<HTMLLIElement | null> = [] // Declare and initialize menuItemRefs

  menuListRef = React.createRef<HTMLUListElement>()

  constructor(props: NavProps) {
    super(props)
    // Initialize menuItemRefs array in the constructor
    this.state = {
      activeSubMenu: null,
      hoveredItemIndex: null,
      arrowPosition: '50%', // Initialize arrowPosition
      activeMenuIndex: null, // New state to track active menu index
      isMenuOpen: false,
    }
    this.menuItemRefs = []
  }
  // Method to toggle the sidebar/menu

  calculateArrowPosition = (index: number) => {
    const menuItem = this.menuItemRefs[index]
    if (!menuItem || !this.menuListRef.current) {
      console.log('Menu item or menu list not found')
      return '50%'
    }

    const menuRect = menuItem.getBoundingClientRect()
    const menuListRect = this.menuListRef.current.getBoundingClientRect()

    const menuItemCenter = menuRect.left + menuRect.width / 2 - menuListRect.left
    console.log('menuRect:', menuRect)
    console.log('menuListRect:', menuListRect)

    console.log('menuItemCenter:', menuItemCenter)

    return `${menuItemCenter}px`
  }

  handleMouseEnter = (menuTitle, index) => {
    const arrowPosition = this.calculateArrowPosition(index)
    console.log('Hovered on: ', menuTitle, ' | Calculated Arrow Position: ', arrowPosition)
    this.setState({ activeSubMenu: menuTitle, arrowPosition, activeMenuIndex: index })
  }

  handleMouseLeave = () => {
    this.setState({ activeSubMenu: null, activeMenuIndex: null })
  }

  toggleSubMenu = (menuTitle) => {
    this.setState((prevState) => ({
      activeSubMenu: prevState.activeSubMenu === menuTitle ? null : menuTitle,
      // Keep or update other state properties as necessary
    }))
  }

  renderSubMenu = () => {
    const { activeSubMenu, arrowPosition } = this.state
    let submenuItems: SubMenuItem[] = []

    menuItemsData.forEach((item) => {
      if (item.title === activeSubMenu) {
        submenuItems = item.submenu || []
      }
    })

    if (submenuItems.length > 0) {
      return (
        <div styleName="submenu_block" style={{ display: 'block' }}>
          <div styleName="submenu" style={{ '--arrow-position': arrowPosition }}>
            {submenuItems.map((item, index) => (
              <div key={index} style={{ padding: '1% 2%', margin: '8% 0', width: '300px' }}>
                {item?.title === 'Farming' ? (
                  <a
                    href={item.url}
                    style={{
                      textAlign: 'left',
                      cursor: 'default',
                      background: '#131313',
                      padding: '4%',
                    }}
                  >
                    {item.title} <span style={{ fontSize: '8px' }}>Coming Soon</span>
                    <br></br>
                    <span styleName="sub_info" style={{ textAlign: 'left' }}>
                      {' '}
                      {item.info}
                    </span>
                  </a>
                ) : (
                  <a href={item.url} style={{ textAlign: 'left', padding: '4%' }}>
                    {item.title}
                    <br></br>
                    <span styleName="sub_info" style={{ textAlign: 'left' }}>
                      {' '}
                      {item.info}
                    </span>
                  </a>
                )}
              </div>
            ))}
          </div>
        </div>
      )
    }
    return null
  }

  render() {
    const {
      menu,
      intl: { locale },
    } = this.props
    console.log(
      'Menu class:',
      `${styles['menu-wrapper']} ${this.state.isMenuOpen ? styles['mobile-visible'] : ''}`
    )

    return (
      <nav styleName="nav-bg" className="nav-bg">
        <div styleName="container">
          <div>
            <div styleName={`menu-wrapper ${this.state.isMenuOpen ? 'mobile-visible' : ''}`}>
              <ul styleName="menu-list" ref={this.menuListRef}>
                {menuItemsData.map(
                  (item, index) =>
                    index < 4 && (
                      <div
                        key={index}
                        onMouseEnter={() => this.handleMouseEnter(item.title, index)}
                        onMouseLeave={this.handleMouseLeave}
                      >
                        <li
                          ref={(el) => (this.menuItemRefs[index] = el)}
                          styleName={`menu-item ${
                            this.state.activeSubMenu === item.title ? 'active' : ''
                          }`}
                        >
                          <a href={item.url}>{item.title}</a>
                          {item.submenu && item.submenu.length > 0 && (
                            <svg
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                              className="sc-dhaItB fhVrZj" // Note: Changed 'class' to 'className' for JSX
                            >
                              <path
                                d="M8.71005 11.71L11.3001 14.3C11.6901 14.69 12.3201 14.69 12.7101 14.3L15.3001 11.71C15.9301 11.08 15.4801 10 14.5901 10H9.41005C8.52005 10 8.08005 11.08 8.71005 11.71Z"
                                fill="#dedf1b"
                              ></path>
                            </svg>
                          )}
                        </li>
                        {this.state.activeSubMenu === item.title && this.renderSubMenu()}
                      </div>
                    )
                )}
                <AppMenu />
              </ul>
            </div>
          </div>
        </div>
      </nav>
    )
  }
}

// @ts-ignore: strictNullChecks
export default injectIntl(Nav)
