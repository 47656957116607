// menuConfig.js
export const menuItemsData = [
  {
    title: 'Wallet',
    url: '#',
    submenu: [
      {
        title: 'Wallet',
        url: 'https://wallet.versx.io/#/',
        info: 'Secure, create, deposit and transfer your cryptocurrency assets. Manage  DeFi assets in full control.',
      },
      {
        title: 'Transactions',
        url: 'https://wallet.versx.io/#/transactions',
        info: 'Track your crypto transactions with ease, allowing you to view detailed history to stay informed.',
      },
      {
        title: 'Create Wallet',
        url: 'https://wallet.versx.io/#/createWallet',
        info: 'Begin your crypto journey with blockchain supported wallet creation. Safely join the ecosystem.',
      },
    ],
  },
  {
    title: 'Staking',
    url: '#',
    submenu: [
      {
        title: 'Static',
        url: 'https://staking.versx.io',
        info: 'Lock your crypto assets, earn rewards. Stable, predictable return without daily management.',
      },
      {
        title: 'Daily',
        url: 'https://dailystaking.versx.io',
        info: 'Maximize earning earn daily rewards. Invite others to enhance experience of staking benefits.',
      },
      {
        title: 'Farming',
        url: '#',
        info: 'Yield-generating, provide liquidity and earn rewards. Robust tool to maximize investment.',
      },
    ],
  },
  {
    title: 'Launchpad',
    url: '#',
    submenu: [
      {
        title: 'Launchpad',
        url: 'https://launchpad.versx.io/',
        info: 'Empowers creators, investors, facilitating projects, offerings essential tools for innovation.',
      },
      {
        title: 'Create Presale',
        url: 'https://launchpad.versx.io/launchpads/create',
        info: 'Initiate and manage presales of your new token with ease, ensuring a successful & powerful launch.',
      },
      {
        title: 'Create Token',
        url: 'https://launchpad.versx.io/create-token',
        info: 'Simplify the process of token creation with our tools allowing for quick launch and integration.',
      },
    ],
  },
  {
    title: 'Swap',
    url: '#',
    submenu: [
      {
        title: 'MultiChain Swap',
        url: 'https://swap.versx.io',
        info: 'Trade your crypto across different chains, offering enhanced flexibility to grow your portfolio.',
      },
      {
        title: 'CrossChain Bridge',
        url: 'https://bridge.versx.io',
        info: 'Connecting different blockchain networks, allowing to transfer your assets with high flexibility.',
      },
      {
        title: 'Solana Swap',
        url: 'https://solswap.versx.io',
        info: 'Specialized for Solana blockchain users, enables direct and efficient trading experience.',
      },
    ],
  },
  {
    title: 'Voting',
    url: '#',

    submenu: [
      {
        title: 'Available Polls',
        url: 'https://voting.versx.io/',
        info: 'Explore active polls, vote on various topics within community. Contribute views on platform decisions.',
      },
      {
        title: 'Create Poll',
        url: 'https://voting.versx.io/create-poll',
        info: 'Launch your own poll to gather community feedback or make collective decisions by voting parameters.',
      },
      {
        title: 'My Polls',
        url: 'https://voting.versx.io/my-polls',
        info: 'Personalized dashboard for users to view their polls, voting results, and engagement.',
      },
    ],
  },
  {
    title: 'Locker',
    url: '#',
    submenu: [
      {
        title: 'Dashboard',
        url: 'https://locker.versx.io/',
        info: 'Vital Dashboard for liquidity of tokens by enabling users to lock LP tokens for designated periods.',
      },
      {
        title: 'Create Lock',
        url: 'https://locker.versx.io/token-locker/lock',
        info: 'Initiate LP token to stable liquidity, ensure long-term engagement while protecting their investments.',
      },
      {
        title: 'All Locks',
        url: 'https://locker.versx.io/token-locker',
        info: 'Comprehensive overview of the entire range of locked tokens, liquidity across the platform.',
      },
    ],
  },

  {
    title: 'Exchange',
    url: '#',
    submenu: [],
  },
]
