import React from 'react'

function LogoComponent() {
  return (
    <>
      <style>
        {`
      .logoWrapper {
        display: block;
        width: 8rem;
        height:auto;
        vertical-align: middle;
         outline: none;
        margin-left: 1rem;
      }

      @media (max-width: 640px) {
        .logoWrapper {
            display: block;
            width: 8rem;
            height:auto;
            vertical-align: middle;
             outline: none;
            margin-left: 1rem;
        }
      }
    `}
      </style>
      <svg
        className="logoWrapper"
        id="Layer_1"
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 3716.6 595.57"
      >
        <polygon
          points="27.41 43.06 138.05 43.06 364.63 438.01 593.13 42.58 701.85 42.58 407.92 552.02 321.33 552.02 27.41 43.06"
          style={{ fill: '#dedf1a' }}
        />
        <path
          d="M936.6,43.06h467.58v94.29H1031.85V251.84h299.69v94.77H1031.85v111.6h372.33v93.33H935.64S936.6,44.51,936.6,43.06Z"
          style={{ fill: '#dedf1a' }}
        />
        <path
          d="M2041.58,378.84h31.75s57.91-1.76,83.49-55.33a103.19,103.19,0,0,0,9.84-44.48V142.86a99.86,99.86,0,0,0-22.55-63.74c-13.64-16.45-35.17-31.57-68.85-34.62L1657.7,44V551.53h93.81V377.39h165L2064.19,552.5h102.95V525.56Zm-289.11-95.25V138.79l317.49-.48V283.59Z"
          style={{ fill: '#dedf1a' }}
        />
        <path
          d="M2834.36,181.12h94.76V144.36a106,106,0,0,0-22.85-66.29c-9.95-12.43-23.89-24.06-43.19-30.13A88.69,88.69,0,0,0,2836.44,44H2511.09s-44-2.46-72.72,38.43a99.55,99.55,0,0,0-17.72,57.25V251.34a92.55,92.55,0,0,0,24.5,63.27c12.78,13.69,32.08,26,61.13,30.55h327.11V456.77h-319.9V414.92h-94.76v57.72a73.15,73.15,0,0,0,2,7.66A101.64,101.64,0,0,0,2518,552h313.46a99.09,99.09,0,0,0,57.85-18.24,91.82,91.82,0,0,0,34.73-48.61,106.79,106.79,0,0,0,4.58-31.48V350.38a103.33,103.33,0,0,0-26-69.06c-13.59-15.16-33.62-28.4-62.54-30H2514.94V137.83h319.9Z"
          style={{ fill: '#dedf1a' }}
        />
        <polygon
          points="3181.92 43.06 3284.38 43.06 3435.19 222.74 3588.89 42.34 3689.19 42.34 3689.19 68.32 3497.97 299.22 3689.19 526.52 3688.71 552.02 3589.13 552.98 3435.91 373.55 3285.82 552.5 3181.92 552.5 3181.92 527.96 3373.14 298.5 3181.2 69.76 3181.92 43.06"
          style={{ fill: '#dedf1a' }}
        />
      </svg>{' '}
    </>
  )
}

export default LogoComponent
