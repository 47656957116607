import constants from '../constants'
import {
  BLOCKCHAIN as BLOCKCHAIN_TYPE,
  COIN_DATA,
  COIN_TYPE,
  TOKEN_STANDARD,
} from '../constants/COINS'
import typeforce from './typeforce'

class TokenRegistrar {
  readonly blockchainType: string // BLOCKCHAIN_TYPE

  readonly tokenStandard: string // TOKEN_STANDARD

  readonly coinType: string // COIN_TYPE

  readonly blockchainModel: string // COIN_DATA[baseCurrency].model

  readonly isCoinAddress: (value: any) => boolean

  readonly isPublicKey: string | ((value: any) => boolean)

  constructor(params) {
    const { blockchainType, tokenStandard, coinType, blockchainModel, isCoinAddress, isPublicKey } =
      params

    this.blockchainType = blockchainType
    this.tokenStandard = tokenStandard
    this.coinType = coinType
    this.blockchainModel = blockchainModel
    this.isCoinAddress = isCoinAddress
    this.isPublicKey = isPublicKey
  }

  register = (code, precision) => {
    const tokenCode = `{${this.blockchainType}}${code}`
    constants.COINS[tokenCode] = tokenCode.toUpperCase()
    constants.COIN_DATA[tokenCode.toUpperCase()] = {
      ticker: tokenCode.toUpperCase(),
      name: code.toUpperCase(),
      blockchain: this.blockchainType,
      standard: this.tokenStandard,
      type: this.coinType,
      model: this.blockchainModel,
      precision,
    }
    typeforce.isCoinAddress[tokenCode.toUpperCase()] = this.isCoinAddress
    typeforce.isPublicKey[tokenCode.toUpperCase()] = this.isPublicKey
  }
}

export default {
  erc20: new TokenRegistrar({
    blockchainType: BLOCKCHAIN_TYPE.ETH,
    tokenStandard: TOKEN_STANDARD.ERC20,
    coinType: COIN_TYPE.ETH_TOKEN,
    blockchainModel: COIN_DATA.ETH.model,
    isCoinAddress: typeforce.isCoinAddress.ETH,
    isPublicKey: typeforce.isPublicKey.ETH,
  }),
  bep20: new TokenRegistrar({
    blockchainType: BLOCKCHAIN_TYPE.BNB,
    tokenStandard: TOKEN_STANDARD.BEP20,
    coinType: COIN_TYPE.BNB_TOKEN,
    blockchainModel: COIN_DATA.BNB.model,
    isCoinAddress: typeforce.isCoinAddress.BNB,
    isPublicKey: typeforce.isPublicKey.BNB,
  }),

  erc20avax: new TokenRegistrar({
    blockchainType: BLOCKCHAIN_TYPE.AVAX,
    tokenStandard: TOKEN_STANDARD.ERC20AVAX,
    coinType: COIN_TYPE.AVAX_TOKEN,
    blockchainModel: COIN_DATA.AVAX.model,
    isCoinAddress: typeforce.isCoinAddress.AVAX,
    isPublicKey: typeforce.isPublicKey.AVAX,
  }),

  erc20matic: new TokenRegistrar({
    blockchainType: BLOCKCHAIN_TYPE.MATIC,
    tokenStandard: TOKEN_STANDARD.ERC20MATIC,
    coinType: COIN_TYPE.MATIC_TOKEN,
    blockchainModel: COIN_DATA.MATIC.model,
    isCoinAddress: typeforce.isCoinAddress.MATIC,
    isPublicKey: typeforce.isPublicKey.MATIC,
  }),
}
