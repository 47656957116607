export const ZERO_ADDRESS = '0x0000000000000000000000000000000000000000'
export const EVM_COIN_ADDRESS = '0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee'

export const WrapperCurrency = {
  // mainnet
  1: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
  56: '0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c',
  137: '0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270',
  100: '0xe91D153E0b41518A2Ce8Dd3D7944Fa863463a97d',
  250: '0x21be370D5312f44cB42ce377BC9b8a0cEF1A4C83',
  1285: '0xf50225a84382c74cbdea10b0c176f71fc3de0c4d',
  43114: '0xB31f66AA3C1e785363F0875A1B74E27b85FD66c7',
  42161: '',
  1666600000: '0xcf664087a5bb0237a0bad6742852ec6c8d69a27a',
  1313161554: '0xc9bdeed33cd01541e1eed10f90519d2c06fe3feb',
  4181: '0xb61d26717dbd2ea6ebf4fadae02a28782f86864c',
  180: '0xCc9bD40124EfedF6F198B8b5b50697dC635FaaC4',
  144: '0x587fe3Fdb7EcD6b134E1556A3Cd083ccF6e7f1B8', // phi-v2
  40821: '0x325A2531D28e22D37935B65bd718D1244968dAae', // Fokawa (FKW)
  // testnet
  4: '0xc778417e063141139fce010982780140aa0cd5ab',
  97: '0xae13d989dac2f0debff460ac112a837c89baa7cd',
  80001: '0x9c3c9283d3e44854697cd22d3faa240cfb032889',
  77: '0x79D5C019F2515Cbc0596170Da44FCd26412c4f83',
  4002: '0xf1277d1Ed8AD466beddF92ef448A132661956621',
  1287: '0xbd23fCD60bD2682dea6A3aad84b498c54d56c494',
  43113: '0xD9D01A9F7C810EC035C0e42cB9E80Ef44D7f8692',
  181: '',
  421611: '',
  1666700000: '',
  1313161556: '',
}

export default {
  ZERO_ADDRESS,
  EVM_COIN_ADDRESS,
  WrapperCurrency,
}
