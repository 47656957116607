import { isMobile } from 'react-device-detect'

import { Switch, Route } from 'react-router-dom'

import { links } from 'helpers'
import LocalStorage from 'pages/LocalStorage/LocalStorage'
import SwapComponent from 'pages/Swap/Swap'
import TurboSwap from 'pages/TurboSwap/TurboSwap'
import History from 'pages/History/History'
import CreateWallet from 'pages/CreateWallet/CreateWallet'
import NotFound from 'pages/NotFound/NotFound'
import Wallet from 'pages/Wallet/Wallet'
import Exchange from 'shared/pages/Exchange'
import CurrencyWallet from 'pages/CurrencyWallet/CurrencyWallet'
import Transaction from 'pages/Transaction/Transaction'
import BtcMultisignProcessor from 'pages/Multisign/Btc/Btc'

import MarketmakerPromo from 'pages/Marketmaker/MarketmakerPromo'
import MarketmakerSettings from 'pages/Marketmaker/MarketmakerSettings'

import CreateInvoice from 'pages/Invoices/CreateInvoice'
import InvoicesList from 'pages/Invoices/InvoicesList'
import Invoice from 'pages/Invoices/Invoice'

import ScrollToTop from '../components/layout/ScrollToTop/ScrollToTop'
import SaveMnemonicModal from 'components/modals/SaveMnemonicModal/SaveMnemonicModal'
import SaveKeysModal from 'components/modals/SaveKeysModal/SaveKeysModal'

import RestoreWalletSelectMethod from 'components/modals/RestoreWalletSelectMethod/RestoreWalletSelectMethod'
import ShamirsSecretRestory from 'components/modals/ShamirsSecretRestory/ShamirsSecretRestory'
import RestoryMnemonicWallet from 'components/modals/RestoryMnemonicWallet/RestoryMnemonicWallet'
import Transactions from 'shared/pages/History/Transactions'
import RequireAuth from 'shared/RequireAuth'
import Login from 'shared/Login'

const routes = (
  <ScrollToTop>
    <Switch>
      <Route path="/login" component={Login} />

      <Route
        exact
        path={`/:page(exit)`}
        render={(props) => (
          <RequireAuth>
            <Wallet {...props} />
          </RequireAuth>
        )}
      />

      <Route
        path={`${links.atomicSwap}/:orderId`}
        render={(props) => (
          <RequireAuth>
            <SwapComponent {...props} />
          </RequireAuth>
        )}
      />
      <Route
        path={`${links.turboSwap}/:orderId`}
        render={(props) => (
          <RequireAuth>
            <TurboSwap {...props} />
          </RequireAuth>
        )}
      />

      <Route
        path={`/:ticker(btc|eth|bnb|matic|arbeth|aureth|xdai|ftm|avax|movr|one|phi_v1|phi|fkw|ame|ghost|next)/tx/:tx?`}
        render={(props) => (
          <RequireAuth>
            <Transaction {...props} />
          </RequireAuth>
        )}
      />
      <Route
        path={`/:token(token)/:ticker/tx/:tx?`}
        render={(props) => (
          <RequireAuth>
            <Transaction {...props} />
          </RequireAuth>
        )}
      />

      <Route
        path={`/:ticker(btc|eth|bnb|matic|arbeth|aureth|xdai|ftm|avax|movr|one|phi_v1|phi|fkw|ame|ghost|next)/:address/:action(receive|send)?`}
        render={(props) => (
          <RequireAuth>
            <CurrencyWallet {...props} />
          </RequireAuth>
        )}
      />
      <Route
        path={`/:token(token)/:ticker/:address/:action(receive|send)?`}
        render={(props) => (
          <RequireAuth>
            <CurrencyWallet {...props} />
          </RequireAuth>
        )}
      />
      <Route
        path={`/:token(token)/:ticker/:address`}
        render={(props) => (
          <RequireAuth>
            <CurrencyWallet {...props} />
          </RequireAuth>
        )}
      />
      <Route
        path={`/:token(token)/:ticker/:address/withdraw`}
        render={(props) => (
          <RequireAuth>
            <CurrencyWallet {...props} />
          </RequireAuth>
        )}
      />
      <Route
        path={`/:fullName-wallet/:address?`}
        render={(props) => (
          <RequireAuth>
            <CurrencyWallet {...props} />
          </RequireAuth>
        )}
      />

      <Route
        path={`${links.exchange}/quick/createOrder`}
        render={(props) => (
          <RequireAuth>
            <Exchange {...props} />
          </RequireAuth>
        )}
      />
      <Route
        path={`${links.exchange}/quick/:sell-to-:buy`}
        render={(props) => (
          <RequireAuth>
            <Exchange {...props} />
          </RequireAuth>
        )}
      />
      <Route
        path={`${links.exchange}/quick`}
        render={(props) => (
          <RequireAuth>
            <Exchange {...props} />
          </RequireAuth>
        )}
      />
      <Route
        path={`${links.exchange}/:sell-to-:buy/:linkedOrderId`}
        render={(props) => (
          <RequireAuth>
            <Exchange {...props} />
          </RequireAuth>
        )}
      />
      <Route
        path={`${links.exchange}/:sell-to-:buy`}
        render={(props) => (
          <RequireAuth>
            <Exchange {...props} />
          </RequireAuth>
        )}
      />
      <Route
        path={`${links.exchange}`}
        render={(props) => (
          <RequireAuth>
            <Exchange {...props} />
          </RequireAuth>
        )}
      />

      <Route path={`${links.localStorage}`} component={LocalStorage} />

      <Route
        path={`${links.send}/:currency/:address/:amount`}
        render={(props) => (
          <RequireAuth>
            <Wallet {...props} />
          </RequireAuth>
        )}
      />
      <Route
        path={`${links.wallet}`}
        render={(props) => (
          <RequireAuth>
            <Wallet {...props} />
          </RequireAuth>
        )}
      />

      <Route
        exact
        path={`${links.createWallet}`}
        render={(props) => (
          <RequireAuth>
            <CreateWallet {...props} />
          </RequireAuth>
        )}
      />
      <Route
        path={`${links.createWallet}/:currency`}
        render={(props) => (
          <RequireAuth>
            <CreateWallet {...props} />
          </RequireAuth>
        )}
      />
      <Route
        path={`${links.restoreWallet}`}
        render={(props) => (
          <RequireAuth>
            <RestoreWalletSelectMethod {...props} />
          </RequireAuth>
        )}
      />
      <Route
        path={`${links.restoreWalletMnemonic}`}
        render={(props) => (
          <RequireAuth>
            <RestoryMnemonicWallet {...props} />
          </RequireAuth>
        )}
      />
      <Route
        path={`${links.restoreWalletShamirs}`}
        render={(props) => (
          <RequireAuth>
            <ShamirsSecretRestory {...props} />
          </RequireAuth>
        )}
      />

      <Route
        path={`${links.multisign}/btc/:action/:data/:peer`}
        render={(props) => (
          <RequireAuth>
            <BtcMultisignProcessor {...props} />
          </RequireAuth>
        )}
      />
      <Route
        path={`${links.multisign}/btc/:action/:data`}
        render={(props) => (
          <RequireAuth>
            <BtcMultisignProcessor {...props} />
          </RequireAuth>
        )}
      />

      <Route
        path={`${links.createInvoice}/:type/:wallet`}
        render={(props) => (
          <RequireAuth>
            <CreateInvoice {...props} />
          </RequireAuth>
        )}
      />
      {isMobile && (
        <Route
          path={`${links.invoices}/:type?/:address?`}
          render={(props) => (
            <RequireAuth>
              <InvoicesList {...props} />
            </RequireAuth>
          )}
        />
      )}
      <Route
        path={`${links.invoice}/:uniqhash?/:doshare?`}
        render={(props) => (
          <RequireAuth>
            <Invoice {...props} />
          </RequireAuth>
        )}
      />

      <Route
        path={`${links.savePrivateSeed}`}
        render={(props) => (
          <RequireAuth>
            <SaveMnemonicModal {...props} />
          </RequireAuth>
        )}
      />
      <Route
        path={`${links.savePrivateKeys}`}
        render={(props) => (
          <RequireAuth>
            <SaveKeysModal {...props} />
          </RequireAuth>
        )}
      />

      <Route exact path={`${links.notFound}`} component={NotFound} />

      <Route
        exact
        path={`/`}
        render={(props) => (
          <RequireAuth>
            <Wallet {...props} />
          </RequireAuth>
        )}
      />
      <Route
        exact
        path={`${links.connectWallet}`}
        render={(props) => (
          <RequireAuth>
            <Wallet {...props} />
          </RequireAuth>
        )}
      />
      <Route
        exact
        path={`/transactions`}
        render={(props) => (
          <RequireAuth>
            <Transactions {...props} />
          </RequireAuth>
        )}
      />

      <Route
        exact
        path={`${links.marketmaker}`}
        render={(props) => (
          <RequireAuth>
            <MarketmakerPromo {...props} />
          </RequireAuth>
        )}
      />
      <Route
        exact
        path={`${links.marketmaker_short}`}
        render={(props) => (
          <RequireAuth>
            <MarketmakerPromo {...props} />
          </RequireAuth>
        )}
      />
      <Route
        path={`${links.marketmaker}/:token/:utxoCoin?`}
        render={(props) => (
          <RequireAuth>
            <MarketmakerSettings {...props} />
          </RequireAuth>
        )}
      />
      <Route
        path={`${links.marketmaker_short}/:token/:utxoCoin?`}
        render={(props) => (
          <RequireAuth>
            <MarketmakerSettings {...props} />
          </RequireAuth>
        )}
      />

      {/* In desktop mode - the history is shown in the wallet design */}
      {!isMobile && (
        <Switch>
          <Route
            exact
            path={`/:page(invoices)/:type?/:address?`}
            render={(props) => (
              <RequireAuth>
                <Wallet {...props} />
              </RequireAuth>
            )}
          />
          <Route
            exact
            path={`/:page(history)`}
            render={(props) => (
              <RequireAuth>
                <Wallet {...props} />
              </RequireAuth>
            )}
          />
        </Switch>
      )}
      {isMobile && (
        <Switch>
          <Route
            exact
            path={`${links.history}/(btc)?/:address?`}
            render={(props) => (
              <RequireAuth>
                <History {...props} />
              </RequireAuth>
            )}
          />
          <Route
            exact
            path={`/:page(invoices)/:type?/:address?`}
            render={(props) => (
              <RequireAuth>
                <History {...props} />
              </RequireAuth>
            )}
          />
        </Switch>
      )}
      <Route
        path={`${links.currencyWallet}`}
        render={(props) => (
          <RequireAuth>
            <Wallet {...props} />
          </RequireAuth>
        )}
      />

      <Route component={NotFound} />
    </Switch>
  </ScrollToTop>
)

export default routes
