import React from 'react'
import { CircleX, MenuIcon } from 'lucide-react'

type ToggleSidebarButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  toggleSidebar: () => void
  isSidebarOpen: boolean // New prop to determine icon state
}

export const ToggleSidebarButton: React.FC<ToggleSidebarButtonProps> = ({
  toggleSidebar,
  isSidebarOpen,
  ...props
}) => {
  console.log('props', props)
  console.log('toggleSidebar', toggleSidebar)

  return (
    <button
      style={{ marginLeft: '9px ' }}
      {...props} // Spread remaining props on the button element
      onClick={() => toggleSidebar()} // Use the custom function on click
    >
      {isSidebarOpen ? (
        <CircleX color="#DEDF1B" /> // Show close icon when sidebar is open
      ) : (
        <MenuIcon color="#DEDF1B" /> // Show menu icon when sidebar is closed
      )}{' '}
    </button>
  )
}
