// AuthContext.js

import React, { createContext, useContext, useState } from 'react'

const AuthContext = createContext(null)
///@ts-ignore
export function AuthProvider({ children }) {
  const [isAuthenticated, setIsAuthenticated] = useState(false)

  const login = (password: any) => {
    if (password === 'znjGV-uGA@%WD{s') {
      setIsAuthenticated(true)
      return true
    }
    return false
  }

  const logout = () => {
    setIsAuthenticated(false)
  }

  return (
    //@ts-ignore
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <AuthContext.Provider value={{ isAuthenticated, login, logout }}>
      {children}
    </AuthContext.Provider>
  )
}

export const useAuth = () => useContext(AuthContext)
