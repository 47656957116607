import React from 'react'

import SidebarItem from './SidebarItem'
import { useEffect, useState } from 'react'
import { menuItemsData } from '../MenuItemsData'
import { useLocation } from 'react-router-dom'
import CSSModules from 'react-css-modules'
import styles from './sidebar.css'
function Sidebar() {
  const location = useLocation()
  const [isSidebarOpen, setIsSidebarOpen] = useState(true)

  const closeSidebar = () => setIsSidebarOpen(!isSidebarOpen)

  useEffect(() => {
    closeSidebar()
  }, [location])
  return (
    <div className="my_sidebar">
      {isSidebarOpen && (
        <div
          style={{
            position: 'fixed',
            inset: 0,
            zIndex: 20,
            width: '100%',
            height: '100%',
          }}
          onClick={() => closeSidebar()}
        ></div>
      )}
      <aside
        style={{
          position: 'fixed',
          zIndex: 20,
          backgroundColor: 'black',
          borderColor: 'black',
          height: '100%',
          top: 0,
          transition: 'transform 300ms',
          paddingTop: '5rem', // 20px top padding
          maxWidth: '100%',
          width: '250px',
          transform: isSidebarOpen ? 'translateX(0)' : 'translateX(0px)',
        }}
      >
        <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
          <div
            style={{
              flex: 1,
              display: 'flex',
              padding: '12px 0',
              gap: '10px',
              flexDirection: 'column',
              overflowY: 'auto',
            }}
          >
            {menuItemsData?.map((item, key) => (
              <SidebarItem key={key} item={item} />
            ))}
          </div>
        </div>
      </aside>
    </div>
  )
}
export default CSSModules(Sidebar, styles)
