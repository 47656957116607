import React, { useState } from 'react'
import { withRouter } from 'react-router-dom'
import { useAuth } from './AuthContext'

function Login({ history }) {
  const [password, setPassword] = useState('')
  const [error, setError] = useState('')
  const auth = useAuth()

  const handleSubmit = (event: any) => {
    event.preventDefault()
    //@ts-ignore
    if (auth.login(password)) {
      history.push('/') // Navigate to home page on successful login
    } else {
      setError('Invalid Password')
    }
  }

  return (
    <div style={{ position: 'relative' }}>
      <div className="overlay"></div>{' '}
      <div
        style={{
          display: 'flex',
          zIndex: '999999',
          position: 'relative',
          justifyContent: 'center',
          alignItems: 'center',
          height: '50vh',
          overflow: 'hidden',
        }}
      >
        <div style={{ width: '100%', maxWidth: '456px' }}>
          <div
            style={{
              backgroundColor: 'black',
              border: '1px solid #dedf1b',
              zIndex: 10,
              position: 'relative',
              padding: '12px',
              borderRadius: '8px',
              boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
            }}
          >
            <h2
              style={{
                color: 'white',
                fontSize: '1.125rem',
                fontWeight: 'bold',
                marginBottom: '24px',
              }}
            >
              Authentication Required
            </h2>
            <form onSubmit={handleSubmit} style={{ display: 'flex', flexDirection: 'column' }}>
              <label
                style={{
                  color: '#D1D5DB',
                  textAlign: 'left',
                  fontWeight: 'bold',
                  marginBottom: '8px',
                  fontSize: '0.875rem',
                }}
              >
                Password
              </label>
              <input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                style={{
                  marginBottom: '16px',
                  padding: '12px',
                  borderRadius: '4px',
                  backgroundColor: 'white',
                  color: 'black',
                }}
                placeholder="Enter password"
              />
              <button
                type="submit"
                style={{
                  backgroundColor: 'black',
                  color: 'white',
                  fontWeight: 'bold',
                  padding: '8px 16px',
                  borderRadius: '4px',
                  border: '1px solid #dedf1b',
                }}
              >
                Log in
              </button>
              {error && (
                <p
                  style={{
                    color: 'red',
                    fontSize: 'smaller',
                    fontStyle: 'italic',
                    marginTop: '16px',
                  }}
                >
                  {error}
                </p>
              )}
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default withRouter(Login) // Wrap the component with withRouter to inject history
