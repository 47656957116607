import config from 'helpers/externalConfig'

const { curEnabled } = config.opts
const onlyEvmWallets = !!config?.opts?.ui?.disableInternalWallet

export const defaultPack = [
  ...((!curEnabled || curEnabled.btc) && !onlyEvmWallets
    ? [{ name: 'STRUMP', capture: 'Super Trump' }]
    : []),
  ...((!curEnabled || curEnabled.btc) && !onlyEvmWallets
    ? [{ name: 'BTC', capture: 'Bitcoin' }]
    : []),

  ...(!curEnabled || curEnabled.eth ? [{ name: 'ETH', capture: 'Ethereum' }] : []),

  ...(!curEnabled || curEnabled.bnb ? [{ name: 'BNB', capture: 'Binance Coin' }] : []),

  ...(!curEnabled || curEnabled.matic ? [{ name: 'MATIC', capture: 'MATIC Token' }] : []),

  ...(!curEnabled || curEnabled.arbeth ? [{ name: 'ARBETH', capture: 'Arbitrum ETH' }] : []),

  ...(!curEnabled || curEnabled.avax ? [{ name: 'AVAX', capture: 'Avalanche' }] : []),

  //...(!curEnabled || curEnabled.aureth ? [{ name: 'AURETH', capture: 'Aurora ETH' }] : []),

  ...(config.erc20
    ? [
        //  { name: 'WBTC', capture: 'Wrapped Bitcoin', baseCurrency: 'ETH' },
        { name: 'USDT', capture: 'Tether', baseCurrency: 'ETH' },
        // { name: 'EURS', capture: 'Eurs', baseCurrency: 'ETH' },
      ]
    : []),
  /*
     ...(config.erc20matic ? [{ name: 'ERC20MATIC', capture: 'Token', baseCurrency: 'MATIC' }] : []),
  ...(config.erc20avax ? [{ name: 'ERC20AVAX', capture: 'Token', baseCurrency: 'AVAX' }] : []),
  ...(config.erc20matic ? [{ name: 'WBTC', capture: 'WBTC Token', baseCurrency: 'MATIC' }] : []),

    ...(config.bep20 ? [{ name: 'BTCB', capture: 'BTCB Token', baseCurrency: 'BNB' }] : []),

    */
  ...(config.erc20 ? [{ name: 'ERC20', capture: 'Token', baseCurrency: 'ETH' }] : []),
  ...(config.bep20 ? [{ name: 'BEP20', capture: 'Token', baseCurrency: 'BNB' }] : []),
]
export const widgetPack = [
  ...((!curEnabled || curEnabled.btc) && !onlyEvmWallets
    ? [{ name: 'STRUMP', capture: 'Super Trump' }]
    : []),
  ...((!curEnabled || curEnabled.btc) && !onlyEvmWallets
    ? [{ name: 'BTC', capture: 'Bitcoin' }]
    : []),
  ...(!curEnabled || curEnabled.eth ? [{ name: 'ETH', capture: 'Ethereum' }] : []),
  ...(config.erc20 && (!curEnabled || curEnabled.eth)
    ? [{ name: 'ERC20', capture: 'Token', baseCurrency: 'ETH' }]
    : []),
  ...(!curEnabled || curEnabled.bnb ? [{ name: 'BNB', capture: 'Binance Coin' }] : []),
  ...(config.bep20 && (!curEnabled || curEnabled.bnb)
    ? [{ name: 'BEP20', capture: 'Token', baseCurrency: 'BNB' }]
    : []),
  ...(!curEnabled || curEnabled.matic ? [{ name: 'MATIC', capture: 'MATIC Token' }] : []),
  ...(config.erc20matic && (!curEnabled || curEnabled.matic)
    ? [{ name: 'ERC20MATIC', capture: 'Token', baseCurrency: 'MATIC' }]
    : []),
  ...(!curEnabled || curEnabled.arbeth ? [{ name: 'ARBETH', capture: 'Arbitrum ETH' }] : []),

  ...(!curEnabled || curEnabled.avax ? [{ name: 'AVAX', capture: 'Avalanche' }] : []),
  /*
  ...(config.erc20avax && (!curEnabled || curEnabled.avax)
    ? [{ name: 'ERC20AVAX', capture: 'Token', baseCurrency: 'AVAX' }]
    : []),
*/
  // ...(!curEnabled || curEnabled.aureth ? [{ name: 'AURETH', capture: 'Aurora ETH' }] : []),
  /*...(config.erc20aurora && (!curEnabled || curEnabled.aureth)
    ? [{ name: 'ERC20AURORA', capture: 'Token', baseCurrency: 'AURETH' }]
    : []),*/
]
