import config from 'app-config'

export type TokenStandard = {
  platform: string
  platformKey: string // CoinGecko platform key
  standard: string
  value: string
  currency: string
  explorerApi: string
  explorerApiKey: string
  hasSupportAtomicSwap: boolean
}

const STANDARDS = {
  erc20: {
    platform: 'ethereum',
    platformKey: 'ethereum',
    standard: 'erc20',
    value: 'erc20',
    currency: 'eth',
    explorerApi: config.api.etherscan,
    explorerApiKey: config.api.etherscan_ApiKey,
    hasSupportAtomicSwap: true,
  },
  bep20: {
    platform: 'binance smart chain',
    platformKey: 'binance-smart-chain',
    standard: 'bep20',
    value: 'bep20',
    currency: 'bnb',
    explorerApi: config.api.bscscan,
    explorerApiKey: config.api.bscscan_ApiKey,
    hasSupportAtomicSwap: true,
  },
  erc20matic: {
    platform: 'ethereum',
    platformKey: 'polygon-pos',
    standard: 'erc20matic',
    value: 'erc20matic',
    currency: 'matic',
    explorerApi: config.api.maticscan,
    explorerApiKey: config.api.polygon_ApiKey,
    hasSupportAtomicSwap: true,
  },

  erc20avax: {
    platform: 'ethereum',
    platformKey: 'avalanche',
    standard: 'erc20avax',
    value: 'erc20avax',
    currency: 'avax',
    explorerApi: config.api.avaxscan,
    explorerApiKey: config.api.avax_ApiKey,
    hasSupportAtomicSwap: false,
  },
}

export const EXISTING_STANDARDS = Object.values(STANDARDS)
  .filter(({ standard }) => !!config[standard])
  .map(({ standard }) => standard.toLowerCase())

export default STANDARDS
